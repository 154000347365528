import React from "react";
import ComparisonReportComponent from "../components/ComparisonReportCompoment";
import ComparisonComponent from "../components/ComparisonComponent";

function ComparisonReport() {
  return (
    <div className="flex flex-col w-full gap-y-4">
      <ComparisonComponent comparison={false} />
      <ComparisonReportComponent />;
    </div>
  );
}

export default ComparisonReport;
