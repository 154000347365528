import axios from "axios";

export const getAddress = async (postalCode, houseNumber, token, logOut) => {
  let res = "";
  let err = "";

  try {
    // Make the API request using axios.post
    res = await axios.get(
      `https://staging.pythagorasvastgoedcalculator.nl/api/show-address?postalCode=${postalCode}&houseNumber=${houseNumber}`,

      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } catch (error) {
    if (error.response.status === 401) {
      console.log("TOkken Expired Logginh Out");
      logOut();
    }
    if (error.response) {
      console.log("Get error in fetching addresses:", error.response.data);
      console.log("error", error.response);
    }
    err = error;
  }
  return res || err;
};
